import { m } from 'framer-motion';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// utils
import { fPercent } from 'src/utils/format-number';
// components
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import { MotionViewport, varFade } from 'src/components/animate';
import { useLocales } from 'src/locales';
import { useSettingsContext } from 'src/components/settings';
import { useEffect } from 'react';
import { minWidth } from '@mui/system';

// ----------------------------------------------------------------------

export const SKILLS = [...Array(3)].map((_, index) => ({
  label: ['Partners', 'Customer', 'Booking'][index],
  value: [55, 40, 20][index],
}));

// ----------------------------------------------------------------------

export default function AboutWhatPrivacy({ data }) {
  const theme = useTheme();

  const mdUp = useResponsive('up', 'md');

  const isLight = theme.palette.mode === 'light';

  const settings = useSettingsContext();

  const { t, currentLang } = useLocales();

  const shadow = `-40px 40px 80px ${alpha(
    isLight ? theme.palette.grey[500] : theme.palette.common.black,
    0.24
  )}`;

  return (
    <Container
      component={MotionViewport}
      sx={{
        py: { xs: 3, md: 6 },
        textAlign: { xs: 'start', md: 'unset' },
      }}
    >
      <Grid
        sx={{ direction: 'ltr' }}
        flexDirection={
          currentLang?.label === 'Arabic' || currentLang?.label === 'Urdu' ? 'row-reverse' : 'row'
        }
        container
        alignItems="flex-start"
      >
        {/* <Grid container xs={12} md={6} lg={7} alignItems="center" sx={{ p: { md: 3 } }}>
          <Grid xs={12}>
            <m.div variants={varFade().inUp}>
              <Image
                alt="our office 2"
                src={data?.image}
                ratio="1/1"
                sx={{ borderRadius: 3 }}
              />
            </m.div>
          </Grid>
        </Grid> */}

        <Grid xs={12} md={12} lg={12}>
          <Typography variant="h4" sx={{ mb: 1, mt: 2 }}>
            Privacy Policy for AngelsBond
          </Typography>

          {/* <m.div variants={varFade().inRight}> */}

          <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
            1. Information We Collect
          </Typography>

          <Typography variant="body1">
            We collect the following types of information when you use our services:
          </Typography>

          <Stack sx={{ px: 3, mt: 2 }}>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  Personal Information: Name, email address, phone number, and other contact details
                  provided by you.
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  Health Information: Information related to pregnancy loss (only if provided by you
                  for journaling or support).
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  Usage Data: Information on how you use the app, such as login frequency and
                  interaction with features.
                </Typography>
              </Box>
            </Stack>
          </Stack>

          <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
            2. How We Use Your Information
          </Typography>

          <Typography variant="body1">We use the information collected to:</Typography>

          <Stack sx={{ px: 3, mt: 2 }}>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>Provide emotional support and personalized content.</Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  Communicate updates, promotional materials, and newsletters.
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  Improve our services based on user interactions and feedback.
                </Typography>
              </Box>
            </Stack>
          </Stack>

          <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
            3. Sharing Your Information
          </Typography>

          <Stack sx={{ px: 3, mt: 2 }}>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  Third-Party Services: We may share your information with third-party services
                  necessary to run our platform, such as payment processors (for subscription
                  management) or email service providers.
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>
                  Legal Compliance: We will share your information if required by law or to enforce
                  our legal rights.
                </Typography>
              </Box>
            </Stack>
          </Stack>

          <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
            4. Cookies and Tracking Technologies
          </Typography>

          <Typography variant="body1">
            We use cookies and similar tracking technologies to analyze user behavior and enhance
            the user experience. You may opt out of cookies by adjusting your browser settings.
          </Typography>

          <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
            5. Data Retention
          </Typography>

          <Typography variant="body1">
            We retain personal data only as long as necessary to fulfill the purposes for which it
            was collected. If you wish to delete your data, you may contact us at any time.
          </Typography>

          <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
            6. Security
          </Typography>

          <Typography variant="body1">
            AngelsBond takes reasonable measures to protect your data from unauthorized access.
            However, no online system is completely secure, and we cannot guarantee the absolute
            security of your information.
          </Typography>

          <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
            7. Your Rights
          </Typography>

          <Typography variant="body1">As a user, you have the right to:</Typography>

          <Stack sx={{ px: 3, mt: 2 }}>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>Request access to the personal data we hold about you.</Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>Request correction of any inaccuracies in your data.</Typography>
              </Box>
            </Stack>
            <Stack direction="row" sx={{ my: 1, alignItems: 'center' }} spacing={2}>
              <Iconify icon="icon-park-outline:dot" width={14} style={{ minWidth: '0.85rem' }} />

              <Box sx={{ typography: 'body2' }}>
                <Typography>Request deletion of your personal data.</Typography>
              </Box>
            </Stack>
          </Stack>

          <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
            8. Changes to Privacy Policy
          </Typography>

          <Typography variant="body1">
            We may update this policy from time to time. Any changes will be posted on this page,
            and significant changes will be communicated through email or the platform.
          </Typography>

          <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
            Contact Us
          </Typography>

          <Typography variant="body1">
            For any questions regarding this Privacy Policy, please contact us at{' '}
            <a
              style={{ color: 'black', fontWeight: 600, textDecoration: 'none' }}
              href="mailto:angelsbond.devteam@gmail.com"
            >
              angelsbond.devteam@gmail.com
            </a>
            .
          </Typography>

          {/* </m.div> */}
        </Grid>
      </Grid>
    </Container>
    // </Box>
  );
}

// <div
//    style={{ direction: 'ltr' }}
//    dangerouslySetInnerHTML={{
//      __html: data?.content ? `${data?.content}` : `${t('Loading...')}`,
//    }}
//  />
