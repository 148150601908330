import { m } from 'framer-motion';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// utils
import { fPercent } from 'src/utils/format-number';
// components
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import { MotionViewport, varFade } from 'src/components/animate';
import { useLocales } from 'src/locales';
import { useSettingsContext } from 'src/components/settings';
import { useEffect } from 'react';
import { minWidth } from '@mui/system';

// ----------------------------------------------------------------------

export const SKILLS = [...Array(3)].map((_, index) => ({
  label: ['Partners', 'Customer', 'Booking'][index],
  value: [55, 40, 20][index],
}));

// ----------------------------------------------------------------------

export default function AboutWhatTsc({ data }) {
  const theme = useTheme();

  const mdUp = useResponsive('up', 'md');

  const isLight = theme.palette.mode === 'light';

  const settings = useSettingsContext();

  const { t, currentLang } = useLocales();

  const shadow = `-40px 40px 80px ${alpha(
    isLight ? theme.palette.grey[500] : theme.palette.common.black,
    0.24
  )}`;

  return (
    <Container
      component={MotionViewport}
      sx={{
        py: { xs: 3, md: 6 },
        textAlign: { xs: 'start', md: 'unset' },
      }}
    >
      <Grid
        sx={{ direction: 'ltr' }}
        flexDirection={
          currentLang?.label === 'Arabic' || currentLang?.label === 'Urdu' ? 'row-reverse' : 'row'
        }
        container
        alignItems="flex-start"
      >
        {/* <Grid container xs={12} md={6} lg={7} alignItems="center" sx={{ p: { md: 3 } }}>
          <Grid xs={12}>
            <m.div variants={varFade().inUp}>
              <Image
                alt="our office 2"
                src={data?.image}
                ratio="1/1"
                sx={{ borderRadius: 3 }}
              />
            </m.div>
          </Grid>
        </Grid> */}

        <Grid xs={12} md={12} lg={12}>
          <Typography variant="h4" sx={{ mb: 1, mt: 2 }}>
            Terms & Conditions for AngelsBond
          </Typography>

          {/* <m.div variants={varFade().inRight}> */}

          <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
            1. Introduction
          </Typography>

          <Typography variant="body1">
            Welcome to AngelsBond! By accessing or using our website and services, you agree to
            comply with and be bound by the following Terms and Conditions. Please read them
            carefully before using our services.
          </Typography>

          <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
            2. Use of Our Services
          </Typography>

          <Typography variant="body1">
            AngelsBond provides emotional support and resources for individuals who have experienced
            pregnancy loss. You must be at least 18 years old to use our services. Our services are
            not a replacement for professional medical or mental health advice.
          </Typography>

          <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
            3. User Responsibilities
          </Typography>

          <Typography variant="body1">
            You are responsible for keeping your login credentials secure. You agree to use
            AngelsBond in a respectful manner and not to engage in harmful or inappropriate
            behavior.
          </Typography>

          <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
            4. Intellectual Property
          </Typography>

          <Typography variant="body1">
            All content, including text, graphics, and logos, on the AngelsBond platform is the
            intellectual property of AngelsBond. You may not reproduce, distribute, or modify any
            part of our services without prior written consent.
          </Typography>

          <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
            5. User Content
          </Typography>

          <Typography variant="body1">
            By sharing content (such as journaling entries or community posts), you grant AngelsBond
            a non-exclusive, royalty-free license to use, modify, and distribute your content within
            the platform.
          </Typography>

          <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
            6. Subscription Plans
          </Typography>

          <Typography variant="body1">
            AngelsBond offers various subscription tiers (e.g., Free Tier, Bloom Again Essentials,
            Healing Hearts Elite). All plans are subject to automatic renewal unless canceled before
            the renewal date.
          </Typography>

          <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
            7. Cancellation and Refunds
          </Typography>

          <Typography variant="body1">
            Users may cancel their subscription at any time. Refunds will be issued at AngelsBond's
            discretion, following our refund policy.
          </Typography>

          <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
            8. Limitation of Liability
          </Typography>

          <Typography variant="body1">
            AngelsBond is not liable for any indirect, incidental, or consequential damages arising
            from the use of our platform.
          </Typography>

          <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
            9. Termination
          </Typography>

          <Typography variant="body1">
            AngelsBond reserves the right to terminate or suspend access to any user who violates
            these Terms and Conditions.
          </Typography>

          {/* <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
            10. Governing Law
          </Typography>

          <Typography variant="body1">
            These terms are governed by and construed in accordance with the laws of [insert
            jurisdiction].
          </Typography> */}

          <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
            10. Changes to Terms
          </Typography>

          <Typography variant="body1">
            AngelsBond may modify these terms at any time. The latest version will always be
            available on our website.
          </Typography>

          <Typography variant="h5" sx={{ mb: 1, mt: 2 }}>
            Contact Us
          </Typography>

          <Typography variant="body1">
            For any questions or concerns about these Terms, please contact us at{' '}
            <a
              style={{ color: 'black', fontWeight: 600, textDecoration: 'none' }}
              href="mailto:angelsbond.devteam@gmail.com"
            >
              angelsbond.devteam@gmail.com
            </a>
            .
          </Typography>

          {/* </m.div> */}
        </Grid>
      </Grid>
    </Container>
    // </Box>
  );
}

// <div
//    style={{ direction: 'ltr' }}
//    dangerouslySetInnerHTML={{
//      __html: data?.content ? `${data?.content}` : `${t('Loading...')}`,
//    }}
//  />
