import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
// PRODUCT
const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
const ProductEditPage = lazy(() => import('src/pages/dashboard/product/edit'));
// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// INVOICE
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
// Feelings
const FeelingsListPage = lazy(() => import('src/pages/dashboard/feelings/list'));
const FeelingsCreatePage = lazy(() => import('src/pages/dashboard/feelings/new'));
const FeelingsEditPage = lazy(() => import('src/pages/dashboard/feelings/edit'));
// Contact Support
const ContactSupportListPage = lazy(() => import('src/pages/dashboard/contact-support/list'));
// Pregnancy Journey
const PregnancyJourneyListPage = lazy(() =>
  import('src/pages/dashboard/pregnancy-journey/list-pregnancy-journey')
);
const ConceptionMethodListPage = lazy(() =>
  import('src/pages/dashboard/pregnancy-journey/list-conception-method')
);
const WeekOfPregnancyListPage = lazy(() =>
  import('src/pages/dashboard/pregnancy-journey/list-week-of-pregnancy')
);
// Loss Details
const NumberOfLossesListPage = lazy(() =>
  import('src/pages/dashboard/loss-details/list-number-of-losses')
);
const PreganancyLossListPage = lazy(() =>
  import('src/pages/dashboard/loss-details/list-pregnancy-loss')
);
const YearSinceLossListPage = lazy(() =>
  import('src/pages/dashboard/loss-details/list-year-since-loss')
);
// Healing Journey
const AdditionalSupportListPage = lazy(() =>
  import('src/pages/dashboard/healing-journey/list-additional-support')
);
const CurrentHealingPhaseListPage = lazy(() =>
  import('src/pages/dashboard/healing-journey/list-current-healing-phase')
);
const EmotionalStatusListPage = lazy(() =>
  import('src/pages/dashboard/healing-journey/list-emotional-status')
);
const PreganancyPlanningStatusListPage = lazy(() =>
  import('src/pages/dashboard/healing-journey/list-pregnancy-planning')
);
// BLOG
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));
// JOB
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));
// TOUR
const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));
// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));
// Privacy Page
const PrivacyPage = lazy(() => import('src/pages/dashboard/privacy'));
// Terms and Conditions
const TermsAndConditionsPage = lazy(() => import('src/pages/dashboard/tac'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'admin',
    element: (
      // <AuthGuard>
      <DashboardLayout>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
      //  </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'ecommerce', element: <OverviewEcommercePage /> },
      { path: 'privacy-policy', element: <PrivacyPage /> },
      { path: 'terms-conditions', element: <TermsAndConditionsPage /> },
      { path: 'analytics', element: <OverviewAnalyticsPage /> },
      { path: 'banking', element: <OverviewBankingPage /> },
      { path: 'booking', element: <OverviewBookingPage /> },
      { path: 'file', element: <OverviewFilePage /> },
      {
        path: 'user',
        children: [
          { element: <UserListPage />, index: true },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
        ],
      },
      {
        path: 'feelings',
        children: [
          { element: <FeelingsListPage />, index: true },
          { path: 'list', element: <FeelingsListPage /> },
          { path: 'new', element: <FeelingsCreatePage /> },
          { path: ':id/edit', element: <FeelingsEditPage /> },
        ],
      },
      {
        path: 'contact-support',
        children: [
          { element: <ContactSupportListPage />, index: true },
          { path: 'list', element: <ContactSupportListPage /> },
        ],
      },
      {
        path: 'Pregnancy-Journey',
        children: [
          { element: <PregnancyJourneyListPage />, index: true },
          { path: 'list-pregnancy-journey', element: <PregnancyJourneyListPage /> },
          { path: 'list-conception-method', element: <ConceptionMethodListPage /> },
          { path: 'list-weeks-of-pregnancy-until-loss', element: <WeekOfPregnancyListPage /> },
        ],
      },
      {
        path: 'loss-details',
        children: [
          { element: <PreganancyLossListPage />, index: true },
          { path: 'list-pregnancy-loss', element: <PreganancyLossListPage /> },
          { path: 'list-year-since-loss', element: <YearSinceLossListPage /> },
          { path: 'list-number-of-losses', element: <NumberOfLossesListPage /> },
        ],
      },
      {
        path: 'healing-journey',
        children: [
          { element: <CurrentHealingPhaseListPage />, index: true },
          { path: 'list-current-healing-phase', element: <CurrentHealingPhaseListPage /> },
          { path: 'list-emotional-status', element: <EmotionalStatusListPage /> },
          { path: 'list-pregnancy-planning-status', element: <PreganancyPlanningStatusListPage /> },
          { path: 'list-additional-support', element: <AdditionalSupportListPage /> },
        ],
      },
      {
        path: 'product',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'list', element: <ProductListPage /> },
          { path: ':id', element: <ProductDetailsPage /> },
          { path: 'new', element: <ProductCreatePage /> },
          { path: ':id/edit', element: <ProductEditPage /> },
        ],
      },
      {
        path: 'order',
        children: [
          { element: <OrderListPage />, index: true },
          { path: 'list', element: <OrderListPage /> },
          { path: ':id', element: <OrderDetailsPage /> },
        ],
      },
      {
        path: 'invoice',
        children: [
          { element: <InvoiceListPage />, index: true },
          { path: 'list', element: <InvoiceListPage /> },
          { path: ':id', element: <InvoiceDetailsPage /> },
          { path: ':id/edit', element: <InvoiceEditPage /> },
          { path: 'new', element: <InvoiceCreatePage /> },
        ],
      },
      {
        path: 'post',
        children: [
          { element: <BlogPostsPage />, index: true },
          { path: 'list', element: <BlogPostsPage /> },
          { path: ':title', element: <BlogPostPage /> },
          { path: ':title/edit', element: <BlogEditPostPage /> },
          { path: 'new', element: <BlogNewPostPage /> },
        ],
      },
      {
        path: 'job',
        children: [
          { element: <JobListPage />, index: true },
          { path: 'list', element: <JobListPage /> },
          { path: ':id', element: <JobDetailsPage /> },
          { path: 'new', element: <JobCreatePage /> },
          { path: ':id/edit', element: <JobEditPage /> },
        ],
      },
      {
        path: 'tour',
        children: [
          { element: <TourListPage />, index: true },
          { path: 'list', element: <TourListPage /> },
          { path: ':id', element: <TourDetailsPage /> },
          { path: 'new', element: <TourCreatePage /> },
          { path: ':id/edit', element: <TourEditPage /> },
        ],
      },
      { path: 'file-manager', element: <FileManagerPage /> },
      { path: 'mail', element: <MailPage /> },
      { path: 'chat', element: <ChatPage /> },
      { path: 'calendar', element: <CalendarPage /> },
      { path: 'kanban', element: <KanbanPage /> },
      { path: 'permission', element: <PermissionDeniedPage /> },
      { path: 'blank', element: <BlankPage /> },
    ],
  },
];
